import './scss/index.scss';

interface Props {
    name: string;
    ws?: number;
}

const Empty: React.FC<Props> = ({name,ws}) => {


    return (
        <div className={'date-to'}>
            <div className="date-to-name text text-s14" style={{color:"#718096"}}>
                <b>{name}</b>
            </div>
            <div className="date-to-input" style={{...(ws && {width: ws + "rem"})}}>
                <input type="text" value={'Янв`23 - Дек`24'}   className="date-to-input-in text text-s14"/>
            </div>
        </div>
    );

}

export default Empty;