import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

import {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";

import headerImg from "./assets/img/header.svg";
import fileImg from "./assets/img/file.svg";
import workImg from "./assets/icon/work.svg";
import deleteImg from "./assets/icon/delete.svg";
import {deleteDataBase, getModal, infoEcp, sendFile, updateDB} from "../../services";
import InputCustom from "../../components/inputCustom";
import oneImage from "../index/assets/img/1.jpg";
import State from "../../redux/actions/StateActions";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });


    const [step, setStep] = useState(0);
    const [passShow, setPassShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectCompany, setSelectCompany] = useState(false);
    const [selectCompanyObject, setSelectCompanyObject] = useState({});
    const [passEcp, setPassEcp] = useState('');
    const [fileEcp, setFileEcp] = useState('');
    const [projectList, setProjectList] = useState([]);


    async function getAllProject() {

        setProjectList((await getModal('Compony', '?user_id=' + state_in?.user?.id)).data);

    }

    useEffect(() => {

        getAllProject().then();

    }, [])

    const navigate = useNavigate();

    return (
        <div>
            <div onClick={() => {
                State(
                    {
                        company: {},
                    }
                );
                navigate('/dashboard');
            }} className={'head-control'} style={{backgroundImage: "url(" + headerImg + ")"}}>

            </div>

            <div className="box-fix">
                <div className={'form-create-project'}>
                    <div className="form-create-project-head">
                        <b className={'text text-s32'}>
                            Давайте добавим вашу организацию в Qisap
                        </b>
                        <p className={'text text-s16'} style={{color: "#718096", fontWeight: 500}}>
                            Добавление компании осуществляется <br/>
                            RSA или GOST ключами
                        </p>
                    </div>

                    {
                        projectList.length == 0 && (
                            <>
                                <img className={'file-save'} src={fileImg}/>
                                <div className="box-fix-nav">
                                    <button onClick={() => {
                                    }} className={'btn btn-def btn-full active text text-s16 '}
                                            type="button">
                                        Добавить компанию
                                        <input onChange={(event) => {


                                            let fileName = '' + event.target.files[0].name.split('.');
                                            const fileNameFirst = fileName[0];
                                            fileName = '' + fileName[fileName.length - 1];


                                            let format = event.target.files[0].name.split('.');
                                            format = format[format.length - 1];

                                            let reader = new FileReader();
                                            reader.readAsBinaryString(event.target.files[0]);
                                            reader.onload = async () => {

                                                let file = (await sendFile({
                                                    "files": btoa(reader.result),
                                                    "typeFile": format,
                                                })).data

                                                let fileLink = 'https://api.event-team.kz' + file;

                                                setFileEcp(fileLink);

                                            };
                                            reader.onerror = function (error) {
                                                console.log('Error: ', error);
                                            };

                                        }} type={"file"} className={'file-link'}/>
                                    </button>

                                    <button onClick={() => {
                                        navigate('/dashboard');
                                    }} className={'btn btn-link btn-full  text text-s16 '}
                                            type="submit">
                                        Пропустить
                                    </button>
                                </div>
                            </>
                        )
                    }

                    {
                        fileEcp != '' && (
                            <div style={{
                                position: "fixed",
                                width: "100vw",
                                top: 0,
                                left: 0,
                                zIndex: 100,
                                height: "100vh",
                                backgroundColor: "rgba(000,000,000,0.5)",
                            }}>
                                <div style={{
                                    position: "fixed",
                                    width: "51rem",
                                    padding: "2rem",
                                    boxSizing: "border-box",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    top: "50%",
                                    borderRadius: "1rem",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }}>

                                    <p className={'text text-s18'}>
                                        Введите пароль от эцп ключа
                                    </p>
                                    <div className="input-box">
                                        <input value={passEcp} onChange={(e) => {
                                            setPassEcp(e.target.value);
                                        }} type={passShow ? "text" : "password"}
                                               className={'input input-def text text-s16 '}
                                               placeholder="Ваш пароль"
                                               required/>
                                        <div onClick={() => {
                                            setPassShow(!passShow)
                                        }} className={"input-box-pass-off " + (passShow && 'active')}></div>
                                    </div>
                                    <p style={{margin: "2rem 0"}}></p>
                                    <button onClick={async () => {

                                        if (!loading) {
                                            setLoading(true);
                                            if (passEcp.length > 4) {
                                                const infoEcpGet = await infoEcp({'fileUrl': fileEcp, pass: passEcp});
                                                if (infoEcpGet.status) {

                                                    const updateDataOrder = (await updateDB({
                                                        'save': {
                                                            'name': infoEcpGet?.info?.organization ? infoEcpGet?.info?.organization : infoEcpGet?.info?.commonName,
                                                            'iin': infoEcpGet?.info?.iin,
                                                            'json_sign': JSON.stringify(infoEcpGet?.info),
                                                            'user_id': state_in?.user?.id,
                                                        },
                                                        'id': 0,
                                                        'model_name': 'Compony',
                                                    }, ''));


                                                    setProjectList((await getModal('Compony', '?user_id=' + state_in?.user?.id)).data);

                                                } else {
                                                    alert('Ошибка');
                                                }
                                            } else {
                                                alert('Пароль слишком короткий');
                                            }
                                            setLoading(false);
                                            setFileEcp('');
                                        }


                                    }} className={'btn btn-def text text-s14 '}
                                            style={{padding: "1rem", width: "20rem", display: "grid", placeItems: "center"}}
                                            type="submit">
                                        {/*<span className="loader"></span>*/}
                                        <span>добавить компанию</span>
                                    </button>

                                </div>
                            </div>
                        )
                    }


                    {
                        projectList.length > 0 && (
                            <>

                                <div class="work-list">

                                    {
                                        projectList.map((projectListMap) => {
                                            return (
                                                <button onClick={() => {

                                                    setSelectCompany(projectListMap.id);
                                                    setSelectCompanyObject(projectListMap);
                                                }} className={'btn btn-work  btn-full  text text-s16 '}
                                                        type="submit">
                                                    <div>
                                                        <div
                                                            className={"remember-me " + (selectCompany == projectListMap.id && 'active')}>
                                                            <div className="remember-me_input"></div>
                                                        </div>
                                                    </div>
                                                    <img src={workImg} style={{width: "4.8rem"}}/>

                                                    <div className={'work-name'}>
                                                        <p className={'text text-s16'}>
                                                            <b>{projectListMap.name}</b>
                                                        </p>
                                                        <p className={'text text-s12'}
                                                           style={{color: "#A0AEC0", marginTop: '0.5rem'}}>
                                                            {projectListMap.iin}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <img onClick={async () => {

                                                            await deleteDataBase({
                                                                id: projectListMap.id
                                                            }, 'Compony');

                                                            setProjectList((await getModal('Compony', '?user_id=' + state_in?.user?.id)).data);

                                                            setSelectCompany(false);


                                                        }} src={deleteImg} style={{width: "2.4rem"}}/>
                                                    </div>
                                                </button>
                                            )
                                        })
                                    }


                                </div>
                                <div className="box-fix-nav">
                                    <button onClick={() => {

                                        if (selectCompany) {
                                            State(
                                                {
                                                    company: selectCompanyObject,
                                                }
                                            );
                                            navigate('/dashboard');
                                        }

                                    }} style={{opacity: !selectCompany ? "0.5" : '1'}}
                                            className={'btn btn-def btn-full  text text-s16 '}
                                            type="submit">
                                        Выбрать и начать работу
                                    </button>

                                    <button

                                        className={'btn btn-def btn-full active  text text-s16 '}
                                        type="button">
                                        Добавить компанию

                                        <input onChange={(event) => {


                                            let fileName = '' + event.target.files[0].name.split('.');
                                            const fileNameFirst = fileName[0];
                                            fileName = '' + fileName[fileName.length - 1];


                                            let format = event.target.files[0].name.split('.');
                                            format = format[format.length - 1];

                                            let reader = new FileReader();
                                            reader.readAsBinaryString(event.target.files[0]);
                                            reader.onload = async () => {

                                                let file = (await sendFile({
                                                    "files": btoa(reader.result),
                                                    "typeFile": format,
                                                })).data

                                                let fileLink = 'https://api.event-team.kz' + file;

                                                setFileEcp(fileLink);

                                            };
                                            reader.onerror = function (error) {
                                                console.log('Error: ', error);
                                            };

                                        }} type={"file"} className={'file-link'}/>
                                    </button>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>

        </div>
    );


}