import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

import {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import Step from "./assets/icon/step.svg";
import Date from "../../components/DateTo";
import DateTo from "../../components/DateTo";
import BtnTxtImg from "../../assets/images/button-txt.svg";
import Search from "../../components/Search";
import IndicatorsEl from "../../components/IndicatorsEl";

import grImg from "./assets/img/gr.svg";

import btnImg from "./assets/img/btn.svg";
import noInfoImg from "./assets/img/no-info.svg";
import TableCustom from "../../components/TableCustom";

import plusImg from "../../assets/images/plus.svg";
import {bankCharge, getModal, sendFile} from "../../services";
import empty from "../../components/empty";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [step, setStep] = useState(0);

    const [loader, setLoader] = useState(1);

    const [reset, setReset] = useState(false);
    const [search, setSearch] = useState('');


    const [list, setList] = useState([]);


    async function getAllProject() {

        setList((await getModal('BankCharge', '?user_id=' + state_in?.user?.id + '&compony_id=' + state_in?.company?.id)).data);

    }

    useEffect(() => {

        getAllProject().then();

    }, [reset])

    const navigate = useNavigate();


    let listFix = list.map((listMap) => {
        let parserBox = JSON.parse(listMap.json);

        if ((parserBox)) {
            listMap['id'] = (parserBox['НомерДокумента']);
            listMap['date'] = (parserBox['ДатаДокумента']);
            listMap['typePay'] = (parserBox['НазначениеПлатежа']);
            // listMap['typePay'] = (parserBox['КодНазначенияПлатежа']);

            // listMap['id'] = (parserBox['ДатаОперации']);
            // listMap['id'] = (parserBox['НазначениеПлатежа']);
            listMap['bin'] = (parserBox['ПлательщикБИН_ИИН']);
            listMap['controragent'] = (parserBox['ПолучательНаименование']);
            // listMap['id'] = (parserBox['ВидДокумента']);
            if ('СуммаПриход' in parserBox) {
                listMap['price'] = (parserBox['СуммаПриход']);
                listMap['type'] = 1;
            }
            if ('СуммаРасход' in parserBox) {
                listMap['price'] = (parserBox['СуммаРасход']);
                listMap['type'] = -1;
            }

            if (listMap['type']== -1) {
                listMap['opera'] = "Выплата";
            }

            if (listMap['type'] == 1) {
                listMap['opera'] = "Поступление";
            }


        }
        return listMap;
    }).filter((listMap) => {
        // Convert all relevant fields to lower case and check if they contain the search term
        return Object.values(listMap).some(value =>
            ('' + value).toLowerCase().includes(search.toLowerCase())
        );
    });

    return (
        <Layout>
            <div className={'page'}>
                <div class="page-pos">
                    <div className={'page-header'}>

                        <p className={'page-center-box text text-s32'}>
                            <b>Банк</b>

                            <img style={{width: "4.8rem"}} src={plusImg}/>
                        </p>

                        <div class="page-filter">
                            <Search label={false} onChange={(e) => {
                                setSearch(e.target.value);
                            }} ws={30} name={'Поиск...'}/>

                            <button className={''} style={{position: "relative"}}>
                                <input onChange={(event) => {


                                    let fileName = '' + event.target.files[0].name.split('.');
                                    const fileNameFirst = fileName[0];
                                    fileName = '' + fileName[fileName.length - 1];


                                    let format = event.target.files[0].name.split('.');
                                    format = format[format.length - 1];

                                    let reader = new FileReader();
                                    reader.readAsBinaryString(event.target.files[0]);
                                    reader.onload = async () => {

                                        let file = (await sendFile({
                                            "files": btoa(reader.result),
                                            "typeFile": format,
                                        })).data

                                        let fileLink = 'https://api.event-team.kz' + file;

                                        await bankCharge({
                                            'file': fileLink,
                                            'user_id': state_in?.user?.id,
                                            'compony_id': state_in?.company?.id,
                                        });


                                    };
                                    reader.onerror = function (error) {
                                        console.log('Error: ', error);
                                    };

                                }} type={"file"} className={'file-link'}/>
                                <img src={BtnTxtImg} style={{width: "22.3rem"}}/>
                            </button>
                        </div>

                    </div>

                    <div className="page-body" style={{marginTop: "2.4rem"}}>

                        <TableCustom

                            th={{
                                id: {
                                    name: '№ документа',
                                    w: 17,
                                    option: null
                                },
                                date: {
                                    name: 'Дата',
                                    w: 17,
                                    option: null
                                },
                                opera: {
                                    name: 'Вид операции',
                                    w: 17,
                                    option: ((row) => {

                                        let color = "";
                                        let text = "";
                                        if (row.type == -1) {
                                            color = "#FF784B";
                                        }

                                        if (row.type == 1) {
                                            color = "#22C55E";
                                        }
                                        return (
                                            <div style={{color: color, textTransform: "capitalize"}}>
                                                <b className="text text-s12">{row.opera}</b>
                                            </div>
                                        )
                                    })
                                },
                                controragent: {
                                    name: 'Контрагент',
                                    w: 17,
                                    option: null
                                },
                                price: {
                                    name: 'Сумма',
                                    w: 17,
                                    option: ((row) => {

                                        return (
                                            <div style={{textTransform: "capitalize"}}>
                                                <b className="text text-s12"> {row.type == '1' ? '+' : '-'} {(parseInt(row.price) > 0 ? parseInt(row.price) : 0).toLocaleString()} ₸</b>
                                            </div>
                                        )
                                    })
                                },
                                bin: {
                                    name: 'БИН',
                                    w: 17,
                                    option: null
                                },
                                typePay: {
                                    name: 'Назначение',
                                    w: 30,
                                    option: null
                                },
                            }}
                            tr={
                                listFix
                            }
                        />

                    </div>
                </div>
            </div>
        </Layout>
    );


}