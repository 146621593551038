import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

// @ts-ignore
import CitySelectModal from '../../components/citySelectModal';
import {SetStateAction, useEffect, useState} from "react";


import {getAuth, getUserInfo, postRegister} from "../../services";

import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import oneImage from "./assets/img/1.jpg";

import logoImage from "../../assets/images/logo.svg";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [rememberActive, setRememberActive] = useState(false);

    const [passShow, setPassShow] = useState(false);

    const [error, setError] = useState(false);
    const [errorRegistration, setErrorRegistration] = useState(false);

    const [registration, setRegistration] = useState(false);


    const [sendCode, setSendCode] = useState(false);


    const [inputRegPass, setInputRegPass] = useState('');

    const [regCode, setRegCode] = useState('');

    const [inputRegPassConform, setInputRegPassConform] = useState('');

    const [message, setMessage] = useState('');


    const [emailLogin, setEmailLogin] = useState('');
    const [passLogin, setPassLogin] = useState('');


    const [emailReg, setEmailReg] = useState('');
    const [passReg, setPassReg] = useState('');
    const [nameReg, setNameReg] = useState('');
    const [nameLastReg, setNameLastReg] = useState('');

    useEffect(() => {

    }, [])

    const navigate = useNavigate();

    return (
        <div className={'first-page'}>
            <div className="first-page-left" style={{backgroundImage: "url(" + oneImage + ")"}}>
                <div className="first-page-left__text">
                    <p className={'text text-s40'}>
                        <b> Онбординг юзеров</b>
                    </p>
                    <p className={'text text-s18'}>
                        Your all-in-one solution for tracking sales performance, analyzing trends, and optimizing
                        strategies.
                    </p>
                    <div className="first-page-left__dots"></div>
                </div>
            </div>
            <div className="first-page-right">

                {
                    !registration && (
                        <div className="login-form">
                            <div className="login-form-head">
                                <img className={'logo logo-def'} src={logoImage}/>
                                <b className={'text text-s32'}>Вход в учетную запись</b>
                                <p className={'text text-s16'}>Считайте деньги с удовольствием!</p>
                            </div>

                            {
                                error && (
                                    <p className={'error-message text text-s14'}>
                                        {message}
                                    </p>
                                )
                            }

                            <form onSubmit={async (e) => {
                                e.preventDefault();


                                let auth = (
                                    await getAuth(
                                        {
                                            login: emailLogin,
                                            password: passLogin
                                        }
                                    )
                                );


                                if (auth?.success && auth?.data?.token) {


                                    const userInfo = (await getUserInfo(auth.data.token));

                                    if (userInfo?.success) {
                                        State(
                                            {
                                                token: auth.data.token,
                                                user: userInfo?.message,
                                            }
                                        );
                                    }


                                    navigate('/project');

                                }

                                if (auth?.error) {
                                    setError(true);
                                    setMessage(auth?.error);
                                }


                            }}>
                                <input type="email" onChange={(e) => {
                                    setEmailLogin(e.target.value);
                                    setError(false);
                                }} value={emailLogin}
                                       className={'input input-def text text-s16 ' + (error && 'input-error')}
                                       placeholder="Введите email"
                                       required/>

                                <div className="input-box">
                                    <input value={passLogin} onChange={(e) => {
                                        setPassLogin(e.target.value);
                                        setError(false);
                                    }} type={passShow ? "text" : "password"}
                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                           placeholder="Введите пароль"
                                           required/>
                                    <div onClick={() => {
                                        setPassShow(!passShow)
                                    }} className={"input-box-pass-off " + (passShow && 'active')}></div>
                                </div>
                                <div className="reset-pass-box">
                                    <div onClick={() => {
                                        setRememberActive(!rememberActive);
                                    }} className={"remember-me " + (rememberActive && 'active')}>
                                        <div className="remember-me_input"></div>
                                        <span className={'text text-s16'}><b>Запомнить меня</b></span>
                                    </div>
                                    <a href="#" className="forgot-password text text-s16">Забыли пароль?</a>
                                </div>
                                <button className={'btn btn-def text text-s16 ' + (error && 'btn-error')}
                                        type="submit">Войти в
                                    Qisap
                                </button>
                            </form>

                            <div className="login-form-link">
                                <p className={'text text-s16'}>Еще не пользовались Qisap? <button onClick={() => {
                                    setError(false);
                                    setMessage('');
                                    setRegistration(true);
                                }}
                                                                                                  className="register">
                                    <b>Зарегистрируйтесь</b></button>
                                </p>
                            </div>
                            <div className="login-form-footer text text-s14">
                                <p style={{color: "#718096"}}>
                                    Этот сайт защищен reCAPTCHA, поэтому применяются
                                </p>
                                <p>
                                    Политика конфиденциальности и Условия использования Google.
                                </p>
                            </div>
                        </div>
                    )
                }

                {
                    registration && (
                        <div className="login-form">

                            {
                                !sendCode && (
                                    <div className="login-form-head">
                                        <img className={'logo logo-def'} src={logoImage}/>
                                        <b className={'text text-s32'}>Регистрация нового аккаунта</b>
                                        <p className={'text text-s16'}>Введите свои данные для успешной регистрации</p>
                                    </div>
                                )
                            }

                            {
                                sendCode && (
                                    <div className="login-form-head">
                                        <img className={'logo logo-def'} src={logoImage}/>
                                        <b className={'text text-s32'}>Подтвердите свой адрес электронной почты</b>
                                        <p className={'text text-s16'}>Мы отправили код на почту <b>{emailReg}</b></p>
                                    </div>
                                )
                            }

                            {
                                errorRegistration && (
                                    <p className={'error-message text text-s14'}>
                                        {message}
                                    </p>
                                )
                            }


                            {
                                sendCode && (
                                    <form onSubmit={async (e) => {
                                        e.preventDefault();

                                        let auth = (
                                            await postRegister(
                                                {
                                                    name: nameReg,
                                                    name_last: nameLastReg,
                                                    email: emailReg,
                                                    code: regCode,
                                                    password: passReg
                                                }
                                            )
                                        );


                                        if (auth.success) {


                                            const userInfo = (await getUserInfo(auth.data.token));

                                            if (userInfo?.success) {

                                                setSendCode(true);
                                                setErrorRegistration(false);
                                                setMessage('');

                                                State(
                                                    {
                                                        token: auth.data.token,
                                                        user: userInfo?.message,
                                                    }
                                                );
                                            }

                                             navigate('/project');
                                        }
                                        if (auth?.error) {
                                            setErrorRegistration(true);
                                            setMessage(auth?.error);
                                        }


                                    }}>


                                        <div className="input-box" style={{display: "flex", justifyContent: "center"}}>
                                            <input onChange={(e) => {
                                                setRegCode(e.target.value);
                                            }} value={regCode} type={"text"}
                                                   className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                   placeholder="Подтвердите код"
                                                   style={{width: "20rem"}}
                                                   required/>

                                        </div>

                                        <div className="login-form-footer text text-s14"
                                             style={{marginTop: "2.4rem", marginBottom: "3.2rem"}}>
                                        </div>
                                        <button className={'btn btn-def text text-s16 ' + (error && 'btn-error')}
                                                type="submit">Verify Account
                                        </button>
                                    </form>
                                )
                            }
                            {
                                !sendCode && (
                                    <form onSubmit={async (e) => {
                                        e.preventDefault();

                                        let auth = (
                                            await postRegister(
                                                {
                                                    name: nameReg,
                                                    name_last: nameLastReg,
                                                    email: emailReg,
                                                    password: passReg
                                                }
                                            )
                                        );


                                        if (auth.success) {
                                            setSendCode(true);
                                            setErrorRegistration(false);
                                            setMessage('');
                                        }
                                        if (auth?.error) {
                                            setErrorRegistration(true);
                                            setMessage(auth?.error);
                                        }


                                    }}>

                                        {
                                            (
                                                <div className="input-box-flex">
                                                    <input type="text" onChange={(e) => {
                                                        setErrorRegistration(false);
                                                        setNameReg(e.target.value);
                                                    }}
                                                           value={nameReg}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Введите Имя"
                                                           required/>

                                                    <input type="text" onChange={(e) => {
                                                        setErrorRegistration(false);
                                                        setNameLastReg(e.target.value);
                                                    }}
                                                           value={nameLastReg}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Фамилия"
                                                           required/>
                                                </div>
                                            )
                                        }


                                        <input type="email" onChange={(e) => {
                                            setErrorRegistration(false);
                                            setEmailReg(e.target.value);
                                        }}
                                               value={emailReg}
                                               className={'input input-def text text-s16 ' + (error && 'input-error')}
                                               placeholder="Введите email"
                                               required/>


                                        {
                                            (
                                                <div className="input-box">
                                                    <input onChange={(e) => {
                                                        setInputRegPass(e.target.value);
                                                        setPassReg(e.target.value);
                                                        setErrorRegistration(false);
                                                    }} value={inputRegPass} type={passShow ? "text" : "password"}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Придумайте пароль"
                                                           required/>
                                                    <div onClick={() => {
                                                        setPassShow(!passShow)
                                                    }} className={"input-box-pass-off " + (passShow && 'active')}></div>


                                                </div>
                                            )
                                        }
                                        {
                                            inputRegPass.length > 0 && inputRegPass.length < 8 && (
                                                <div className="error-pass">
                                                    <div className="error-pass-line"
                                                         style={{width: ((inputRegPass.length / 8) * 100) + "%"}}></div>
                                                    <p className={'text text-s12'}>
                                                        😖 Слабый. Пароль должен содержать не менее 8 символов, у
                                                        вас {inputRegPass.length}
                                                    </p>
                                                </div>
                                            )
                                        }


                                        {
                                            inputRegPass != '' && (
                                                <div className="input-box">
                                                    <input onChange={(e) => {
                                                        setErrorRegistration(false);
                                                        setInputRegPassConform(e.target.value);
                                                    }} value={inputRegPassConform} type={passShow ? "text" : "password"}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Подтвердите пароль"
                                                           required/>
                                                    <div onClick={() => {
                                                        setPassShow(!passShow)
                                                    }} className={"input-box-pass-off " + (passShow && 'active')}></div>
                                                </div>
                                            )
                                        }

                                        <div className="login-form-footer text text-s14"
                                             style={{marginTop: "2.4rem", marginBottom: "3.2rem"}}>
                                            <p>
                                        <span
                                            style={{color: "#718096"}}>  Создавая учетную запись, вы соглашаетесь с нашей </span>
                                                <b> Политикой
                                                    конфиденциальности и Политикой электронных коммуникаций.</b>
                                            </p>
                                        </div>
                                        <button className={'btn btn-def text text-s16 ' + (error && 'btn-error')}
                                                type="submit">Зарегистрироваться в Qisap
                                        </button>
                                    </form>
                                )
                            }


                            <div className="login-form-link">
                                <p className={'text text-s16'}>Уже есть аккаунт? <button onClick={() => {
                                    setErrorRegistration('');
                                    setMessage('');
                                    setRegistration(false);
                                }}
                                                                                         className="register">
                                    <b>Войдите</b></button>
                                </p>
                            </div>

                        </div>
                    )
                }

            </div>
        </div>
    );


}