import './scss/index.scss';

interface Props {
    name: string;
    ws?: number;
    label?: boolean;
    onChange?: Function;
}

const Empty: React.FC<Props> = ({name, ws, onChange, label = true}) => {


    return (
        <div className={'date-to'}>

            {
                label && (
                    <div className="date-to-name text text-s14" style={{color: "#718096"}}>
                        <b>{name}</b>
                    </div>
                )
            }
            <div className="date-to-input date-to-input-search"
                 style={{...(!label && {marginTop: "0"}), ...(ws && {width: ws + "rem"})}}>
                <input type="text" onChange={(e) => {
                    if (onChange) {
                        onChange(e);
                    }
                }} placeholder={'Поиск'} className="date-to-input-in text text-s14"/>
            </div>
        </div>
    );

}

export default Empty;