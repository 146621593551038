const INITIAL_STATE = {
    city_select: {},
    user: {},
    compony: {},
    token: false,
};


const stateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'STATE':
            return {...state, ...action.value};
        default:
            return state;
    }
};
export default stateReducer;
