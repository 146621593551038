import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

import {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import Step from "./assets/icon/step.svg";
import Date from "../../components/DateTo";
import DateTo from "../../components/DateTo";
import SelectCustom from "../../components/SelectCustom";
import Search from "../../components/Search";
import IndicatorsEl from "../../components/IndicatorsEl";

import grImg from "./assets/img/gr.svg";

import btnImg from "./assets/img/btn.svg";
import noInfoImg from "./assets/img/no-info.svg";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [step, setStep] = useState(0);

    const [loader, setLoader] = useState(1);


    useEffect(() => {

    }, [])

    const navigate = useNavigate();

    return (
        <Layout>
            <div className={'page'}>
                <div class="page-pos">
                    <div className={'page-header'}>

                        <p className={'text text-s32'}>
                            <b>Показатели</b>
                        </p>

                        <div class="page-filter">
                            <DateTo name={'Период'}/>
                            <SelectCustom name={'Отображения'}/>
                            <Search name={'Юр. лица и счета'}/>
                        </div>

                    </div>

                    <div className="page-body">
                        <div className="page-indicators-top">
                            <div className="page-indicators-left">
                                <p className={'text text-s20'}>
                                    <b>Сводная панель</b>
                                </p>
                                <div className="page-indicators-number">
                                    <IndicatorsEl name={'Поступление денег'}/>
                                    <IndicatorsEl name={'Выплата денег'}/>
                                    <IndicatorsEl name={'Поступило ЭСФ на сумму'}/>
                                    <IndicatorsEl name={'Отправлено ЭСФ на сумму'}/>
                                </div>
                            </div>
                            <div className="page-indicators-right">
                                <img src={grImg}/>
                            </div>
                        </div>

                        <div className="indicators-list-box">
                            <div className="indicators-list">
                                <div className="indicators-list-head">
                                    <p className={'text text-s20'}>
                                        <b>Сводная панель</b>
                                    </p>
                                    <img style={{width: "12.6rem"}} src={btnImg}/>
                                </div>
                                <div className="indicators-list-body">

                                    <img style={{width: "20.3rem"}} src={noInfoImg}/>

                                    <p className={'text text-s16'} style={{color: "#A0AEC0"}}>
                                        Еще нет дебиторских данных
                                    </p>
                                </div>
                            </div>
                            <div className="indicators-list">
                                <div className="indicators-list-head">
                                    <p className={'text text-s20'}>
                                        <b>Кредиторские задолженности</b>
                                    </p>
                                    <img style={{width: "12.6rem"}} src={btnImg}/>
                                </div>
                                <div className="indicators-list-body">

                                    <img style={{width: "20.3rem"}} src={noInfoImg}/>

                                    <p className={'text text-s16'} style={{color: "#A0AEC0"}}>
                                        Еще нет кредиторских данных
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );


}