import React, {useEffect, useState} from 'react';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import StateSlices from "../../redux/slices/State";

import {NavLink} from 'react-router-dom'
import State from "../../redux/actions/StateActions";
import Btn from "../Btn";
import logoImg from "../../assets/images/logo.svg";
import barImg from "./assets/icon/bar.svg";
import bankImg from "./assets/icon/bank.svg";
import invoiceImg from "./assets/icon/invoice.svg";
import aktImg from "./assets/icon/akt.svg";
import arrowImg from "./assets/icon/arrow-sort.svg";
import bookImg from "./assets/icon/open-book.svg";

import helpImg from "./assets/icon/help.svg";
import settingImg from "./assets/icon/setting.svg";


import chevronImg from "./assets/icon/chevron-down.svg";


import upImg from "./assets/icon/up-arrow.svg";
import downImg from "./assets/icon/down-arrow.svg";

import moneyImg from "./assets/icon/money.svg";
import workLImg from "./assets/icon/work.svg";


import notificationsImg from "./assets/icon/notifications.svg";
import workImg from "../../views/projects/assets/icon/work.svg";
import deleteImg from "../../views/projects/assets/icon/delete.svg";


const {REACT_APP_API_PATH} = process.env;

const Layout = (({logo, children, className}) => {

    const lang = useSelector(LangSlices);
    const state_in = useSelector(StateSlices);

    const [menuOpen, setMenuOpen] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);

    const [openBox, setOpenBox] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    useEffect(() => {


    }, []);


    let message_error = [].reverse();


    let urlLink = {
        'Order': 'Все заказы',
        'Tilda': 'Все заказы с сайта',
        'Accounting': 'Учет посещения',
        'AccountingAdmin': 'Всё посещение сотрудников',
        'Product': 'Все оборудование',
        'Sklad': 'Вся аналитика по складу',
        'ProductMenu': 'Все Меню',
        'Personal': 'Весь персонал',
        'Pack': 'Все готовые чек-листы',
        'ProductDown': 'Все списанные товаров групами',
        'Broken': 'Все утеря',
        'Inventory': 'Вся инвентаризация',
        'Role': 'Все роли',
        'Group': 'Все разделы',
        'Repair': 'Вся ремонт доска',
        'ProductTopDown': 'Вся аналитика списания',
        'SkladAn': 'Вся аналитика по складу',
        'ProductTop': 'Вся аналитика',
        'OrderDown': 'Весь просроченый демонтаж',
        'Prihod': 'Весь приход',
        'RepairBoard': 'Вся Ремонтная доска',
        'User': 'Все пользователи'
    };


    let urlPathSplit = location.pathname;


    return (
        <>
            <div>
                <div className={'main-box'}>


                    <NotificationContainer/>
                    <div className={'site-bar ' + (menuOpen && 'active')}>

                        <div className="site-bar__head">
                            <img src={logoImg} style={{width: "10rem"}}/>
                        </div>

                        <div className="site-bar__menu">
                            {/*['Списанные товары', '/ProductDown/browse', 'ProductDown'],*/}


                            {
                                [
                                    ['Показатели', '/indicator', barImg],
                                    ['Банк', '/bank', bankImg],
                                    ['ЭСФ', '/ecf', invoiceImg],
                                    ['Акт сверки', '/akt', aktImg],
                                    ['Дт/Кт', '/dt', arrowImg],
                                    ['Справочники', '/sp', bookImg],
                                ].map((text, index) => {


                                    return (
                                        <NavLink
                                            key={index} to={text[1]}
                                            className={'site-bat-link ' + (urlPathSplit == text[1] && 'active')}>
                                            <div class="site-bat-link-icon"
                                                 style={{maskImage: "url('" + text[2] + "')"}}></div>
                                            <span className={'text text-s16'}>{text[0]}</span>
                                        </NavLink>
                                    )
                                })
                            }


                        </div>


                        <div className="site-bar__menu" style={{marginTop: "auto"}}>
                            {/*['Списанные товары', '/ProductDown/browse', 'ProductDown'],*/}
                            {
                                [
                                    ['Помощь', '/', helpImg],
                                    ['Настройки', '/', settingImg],
                                ].map((text, index) => {


                                    return (
                                        <NavLink
                                            key={index} to={text[1]}
                                            className={'site-bat-link '}>
                                            <img src={text[2]}/>
                                            <span className={'text text-s16'}>{text[0]}</span>
                                        </NavLink>
                                    )
                                })
                            }


                        </div>

                    </div>
                    <main style={{position: 'relative'}}>
                        <div className="nav-head-user">
                            <div onClick={() => {

                                setOpenSetting(!openSetting);
                            }} className="nav-head-user-src">
                                <p className={'text text-s16'}>
                                    {state_in?.authUser?.name}
                                </p>

                                {
                                    openSetting && (
                                        <>
                                            <div className="open-setting-user">
                                                <Btn type={'submit'} onClick={() => {
                                                    State({
                                                        token_: false,
                                                        auth: false,
                                                        authUser: false,
                                                    })
                                                }} className={'btn-def-table text text-s16'} label={'выйти'}/>

                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'ci-while-bk'}>
                            <div className="header-profil">

                                <div className={'btn-head text text-s16 '}>
                                    <img src={workLImg} style={{width: "4.8rem"}}/>


                                    {
                                        state_in?.company?.id && (
                                            <div className={'work-name'}>

                                                <p className={'text text-s16'}>
                                                    <b> {state_in?.company?.name}</b>
                                                </p>

                                                <p className={'text text-s12'}
                                                   style={{color: "#A0AEC0", marginTop: '0.5rem'}}>
                                                    {state_in?.company?.iin}
                                                </p>
                                            </div>
                                        )
                                    }
                                    {
                                        !state_in?.company?.id && (
                                            <div className={'work-name'}>

                                                <p className={'text text-s16'}>
                                                    <b> ТОО “Лучшая компания в мире”</b>
                                                </p>

                                                <p className={'text text-s12'}
                                                   style={{color: "#A0AEC0", marginTop: '0.5rem'}}>
                                                    БИН:123456789012
                                                </p>
                                            </div>
                                        )
                                    }
                                    <img src={chevronImg} style={{width: "1.6rem"}}/>
                                </div>

                                <div className={'btn-head text text-s16 '}>
                                    <img src={moneyImg} style={{width: "4.8rem"}}/>
                                    <div className={'work-name'}>
                                        <p className={'text text-s16'}>
                                            <b>Сальдо ДТ/КТ</b>
                                        </p>
                                        <div className={'diagram-box text text-s12'}
                                             style={{color: "#A0AEC0", marginTop: '0.5rem'}}>
                                            <p className={'diagram-info text text-s12'}
                                               style={{color: "#22C55E", fontWeight: 500}}>
                                                <img src={upImg}/>
                                                + 0₸
                                            </p>
                                            <p className={'diagram-info text text-s12'}
                                               style={{color: "#FF4747", fontWeight: 500}}>
                                                <img src={downImg}/>
                                                - 0₸
                                            </p>
                                        </div>
                                    </div>
                                    <img src={chevronImg} style={{width: "1.6rem"}}/>
                                </div>

                                <div className={'user-name-info'}>
                                    <img src={notificationsImg} style={{width: "6.4rem", marginRight: "1.6rem"}}/>
                                    <div className={'btn-head btn-head-profile text text-s16 '}>
                                        <div src={moneyImg} className={'profile-img'}></div>
                                        <div className={'work-name'}>
                                            <p className={'text text-s16'}>
                                                <b>{state_in?.user?.name} {state_in?.user?.name_last}</b>
                                            </p>
                                            <p className={'text text-s12'}
                                               style={{color: "#A0AEC0", marginTop: '0.5rem'}}>
                                                Оформите подписку
                                            </p>
                                        </div>
                                        <img src={chevronImg} style={{width: "1.6rem"}}/>
                                    </div>
                                </div>


                            </div>
                            {children}
                        </div>
                    </main>

                </div>
            </div>
        </>
    )
});
export default Layout;
