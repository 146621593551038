import './scss/index.scss';

interface Props {
    name: string;
    ws?: number;
}

const Empty: React.FC<Props> = ({name, ws}) => {


    return (
        <div className={'date-to'}>
            <div className="date-to-name text text-s14" style={{color: "#718096"}}>
                <b>{name}</b>
            </div>
            <div className="date-to-input date-to-input-down"   style={{...({paddingLeft: "0"}), ...(ws && {width: ws + "rem"})}}>
                <div className="date-to-input-in text text-s14"
                   >По месяцам
                </div>
            </div>
        </div>
    );

}

export default Empty;