import './scss/index.scss';

interface Props {
    name: string;
}

// @ts-ignore
import monayImg from "./assets/icon/monay_in.svg";

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className="page-indicators-el">
            <div className="page-indicators-el-icon" style={{backgroundImage: "url(" + monayImg + ")"}}></div>
            <div className="page-indicators-el-name">
                <p className={'text text-s14'}>{name}</p>
            </div>
            <div className="page-indicators-el-info">
                <p className={'text text-s20'}><b>0 ₸</b></p>
                <p className={'page-indicators-el-info-price text text-s12'}><span style={{color: "#A867F4"}}>0 ₸</span> - по
                    плану</p>
            </div>
        </div>
    );

}

export default Empty;