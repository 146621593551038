import React from 'react';

import './style.scss';
import Loader from "react-js-loader";


const Article = (({className, onClick, type = 'button',name='',disabled=false, label = '', value, onChange, load = false}) => {

    return (
        <>
            <button disabled={disabled} onClick={onClick} type={type} name={name} value={'sasbs'} className={`ci-btn ci-btn-def ${className}`}>
                {<span style={{opacity: load ? 0 : 1}}>{label}</span>}
                {load && <Loader type="spinner-circle" bgColor={"#FFBD3E"} color={'#FFBD3E'} size={25}/>}
            </button>
        </>
    )
});


export default Article;
