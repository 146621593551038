import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

import {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import Step from "./assets/icon/step.svg";
import Date from "../../components/DateTo";
import DateTo from "../../components/DateTo";
import BtnTxtImg from "../../assets/images/button-txt.svg";
import Search from "../../components/Search";
import IndicatorsEl from "../../components/IndicatorsEl";

import grImg from "./assets/img/gr.svg";

import btnImg from "./assets/img/btn.svg";
import noInfoImg from "./assets/img/no-info.svg";
import TableCustom from "../../components/TableCustom";

import plusImg from "../../assets/images/plus.svg";
import SelectCustom from "../../components/SelectCustom";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [step, setStep] = useState(0);

    const [loader, setLoader] = useState(1);


    useEffect(() => {

    }, [])

    const navigate = useNavigate();

    return (
        <Layout>
            <div className={'page'}>
                <div class="page-pos">
                    <div className={'page-header'}>

                        <p className={'page-center-box text text-s32'}>
                            <b>Дебиторка/Кредиторка</b>

                            <img style={{width: "4.8rem"}} src={plusImg}/>
                        </p>

                        <div class="page-filter">
                            <SelectCustom ws={26} name={'По месяцам'}/>
                            <DateTo ws={26} name={'Период'}/>
                        </div>

                    </div>

                    <div className="page-body" style={{marginTop: "2.4rem"}}>

                        <TableCustom

                            th={{
                                mon: {
                                    name: 'Контрагент',
                                    w: 17,
                                    option: null
                                },
                                demon2: {
                                    name: 'БИН',
                                    w: 17,
                                    option: null
                                },
                                demon3: {
                                    name: 'Дата',
                                    w: 17,
                                    option: null
                                },
                                demon4: {
                                    name: 'Дебиторская задолженность',
                                    w: 17,
                                    option: null
                                },
                                demon5: {
                                    name: 'Кредиторская задолженность',
                                    w: 17,
                                    option: null
                                },
                                demon6: {
                                    name: 'Вид',
                                    w: 10,
                                    option: null
                                },

                            }}
                            tr={
                                []
                            }
                        />

                    </div>
                </div>
            </div>
        </Layout>
    );


}