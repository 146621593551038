import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

import {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import Step from "./assets/icon/step.svg";
import Check from "./assets/icon/check.svg";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [step, setStep] = useState(0);

    const [loader, setLoader] = useState(1);


    useEffect(() => {

    }, [])

    const navigate = useNavigate();

    return (
        <Layout>
            <div className={'dask-box'}>
                <div class="dask-box-body">
                    <div className="form-create-project-head form-create-project-head-full">
                        <div class="dask-box-body-head">
                            <b className={'text text-s32'}>
                                Добро пожаловать в Qisap
                            </b>
                            <p className={'text text-s14'}
                               style={{color: "#718096", fontWeight: 500, marginTop: "1.6rem"}}>
                                Чтобы начать работу в системе и управлять финансами, пройдите несколько простых шагов.
                            </p>
                        </div>

                        <div class="radial-box">
                            <div className="radial radial-noactive">
                                <div className="circle left rotate"><span
                                    style={{transform: "rotate(180deg)"}}></span>
                                </div>
                                <div className="circle right rotate"><span
                                    style={{transform: "rotate(180deg)"}}></span>
                                </div>
                            </div>
                            <div className="radial">
                                <div class="radial-text">
                                    <p className={'text text-s16'}>
                                        {loader}%
                                    </p>
                                </div>
                                <div className="circle left rotate"><span
                                    style={{transform: "rotate(" + (loader <= 50 ? (360 * (loader / 100)) : 180) + "deg)"}}></span>
                                </div>
                                <div className="circle right rotate"><span
                                    style={{transform: "rotate(" + ((loader - 50) > 0 ? (360 * ((loader - 50) / 100)) : 0) + "deg)"}}></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block-flex">
                        <div className={"tytorial-box " + (state_in?.company?.id && 'active')}>
                            <b className="tytorial-box-number text text-s16">
                                {
                                    !state_in?.company?.id && (
                                        1
                                    )
                                }
                                {
                                    state_in?.company?.id && (
                                        <img style={{width: "2.4rem"}} src={Check}/>
                                    )
                                }
                            </b>
                            <div className="tytorial ">
                                <div className="tytorial-img">
                                    <img src={Step}/>
                                </div>
                                <div className="tytorial-name">
                                    <b className={'text text-s16'}>
                                        Создайте организацию
                                    </b>
                                </div>
                                <div className="tytorial-desk">
                                    <p className={'text text-s14'}>
                                        Еще нет дебиторских данных
                                    </p>
                                </div>
                                <div className="tytorial-nav">
                                    <button onClick={() => {
                                        navigate('/project');
                                        setStep(1);
                                    }} className={'btn btn-def btn-mini btn-full active text text-s12 '}
                                            type="submit">
                                        Настроить
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="tytorial-box">
                            <b className="tytorial-box-number text text-s16">
                                2
                            </b>
                            <div className="tytorial">
                                <div className="tytorial-img">
                                    <img src={Step}/>
                                </div>
                                <div className="tytorial-name">
                                    <b className={'text text-s16'}>
                                        Настройте онлайн-интеграцию с банком
                                    </b>
                                </div>
                                <div className="tytorial-desk">
                                    <p className={'text text-s14'}>
                                        Еще нет дебиторских данных
                                    </p>
                                </div>
                                <div className="tytorial-nav">
                                    <button onClick={() => {
                                        setStep(1);
                                    }} className={'btn btn-def btn-mini btn-full active text text-s12 '}
                                            type="submit">
                                        Настроить
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="tytorial-box">
                            <b className="tytorial-box-number text text-s16">
                                3
                            </b>
                            <div className="tytorial">
                                <div className="tytorial-img">
                                    <img src={Step}/>
                                </div>
                                <div className="tytorial-name">
                                    <b className={'text text-s16'}>
                                        Загрузите операции за предыдущий период
                                    </b>
                                </div>
                                <div className="tytorial-desk">
                                    <p className={'text text-s14'}>
                                        Еще нет дебиторских данных
                                    </p>
                                </div>
                                <div className="tytorial-nav">
                                    <button onClick={() => {
                                        setStep(1);
                                    }} className={'btn btn-def btn-mini btn-full active text text-s12 '}
                                            type="submit">
                                        Настроить
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="tytorial-box">
                            <b className="tytorial-box-number text text-s16">
                                4
                            </b>
                            <div className="tytorial">
                                <div className="tytorial-img">
                                    <img src={Step}/>
                                </div>
                                <div className="tytorial-name">
                                    <b className={'text text-s16'}>
                                        Настройте статьи учета
                                    </b>
                                </div>
                                <div className="tytorial-desk">
                                    <p className={'text text-s14'}>
                                        Еще нет дебиторских данных
                                    </p>
                                </div>
                                <div className="tytorial-nav">
                                    <button onClick={() => {
                                        setStep(1);
                                    }} className={'btn btn-def btn-mini btn-full active text text-s12 '}
                                            type="submit">
                                        Настроить
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="tytorial-box">
                            <b className="tytorial-box-number text text-s16">
                                5
                            </b>
                            <div className="tytorial">
                                <div className="tytorial-img">
                                    <img src={Step}/>
                                </div>
                                <div className="tytorial-name">
                                    <b className={'text text-s16'}>
                                        Настройте правила автораспределения платежей
                                    </b>
                                </div>
                                <div className="tytorial-desk">
                                    <p className={'text text-s14'}>
                                        Еще нет дебиторских данных
                                    </p>
                                </div>
                                <div className="tytorial-nav">
                                    <button onClick={() => {
                                        setStep(1);
                                    }} className={'btn btn-def btn-mini btn-full active text text-s12 '}
                                            type="submit">
                                        Настроить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );


}